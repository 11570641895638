import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import theme from './_theme'

const InventoryManagement = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Envanter Yönetimi' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']}  />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='3' />
      <Container variant='wide' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse/>
      </Container>
      <Divider space='6' />
      <Divider space='6' />
      <FeatureOne content={content['feature-one']} />
      <Divider space='5' />
      <Divider space='5' />
      <FeatureOne content={content['feature-two']} reverse />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageSiteInventoryManagementBlockContent {
    allBlockContent(filter: { page: { in: ["site/features/inventory-management", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default InventoryManagement
