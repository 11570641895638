/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */
 import heroBg from './assets/contour-pattern.svg'

export default {
  heroContainer: {
    '::before': {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      top: `0%`,
      left: 0,
      zIndex: -1,
      background: t =>
        `url(${heroBg}) no-repeat center 0, linear-gradient(
          180deg,
          ${t.colors.alphaLighter} 0%,
          ${t.colors.background} 100%
        )`,
      backgroundSize: `100%`
    }
  }
}
